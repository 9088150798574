import { Button, Checkbox, Form, Input, Progress, Row, Tag, Upload } from 'antd'
import React, { useState } from 'react'
import './style.scss'
import TextArea from 'antd/lib/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import { PlusOutlined } from '@ant-design/icons'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { addDoc, collection } from 'firebase/firestore'
import firebase from '../../../firebase'

import { storage } from '../../../firebase'
import moment from 'moment'

const ProjectForm = () => {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [image, setImage] = useState('')
  const [tags, setTags] = useState<any>([])

  // submitting from
  const onFinish = async () => {
    setUploading(true)
    const createdAt = moment().format('YYYY-MM-DD HH:mm:ss')
    const data = await form?.getFieldsValue()
    delete data['tag']
    data.tags = [...tags]
    data.isMobile = data.isMobile || false
    const collectionRef = await collection(firebase, 'projects')
    const payload = await { ...data, image, createdAt, images: [] }
    await addDoc(collectionRef, payload)
    setUploading(false)
    form.resetFields()
    setTags([])
  }

  // run if error  while submitting
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  // uploading image in firebase storage
  const beforeUpload = async (file: any) => {
    setLoading(true)
    const fileName = moment().unix()
    const storageRef = ref(storage, `projects/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setPercent(progress)
      },
      (error) => {
        console.log({ error })
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImage(downloadURL)
          setLoading(false)
        })
      }
    )
  }

  //  adding tag
  const handleSingleTagChange = async () => {
    const e = form.getFieldsValue()?.tag
    const arrayString = e?.split('')

    if (arrayString[arrayString.length - 1] === ',') {
      const tag = e?.split(',')
      if (!tags.includes(tag[0])) {
        setTags([...tags, tag[0]])
      }
      form?.setFieldsValue({ tag: '' })
    }
  }

  // deleting tag
  const deleteTag = (t: string) => {
    const filtredTags = tags.filter((tag: string) => tag !== t)
    setTags([...filtredTags])
  }

  // image upload buton
  const uploadButton = (
    <div className='center' style={{ flexDirection: 'column' }}>
      {loading ? (
        <Progress
          type='circle'
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
          percent={percent}
          format={() => ''}
        />
      ) : (
        <PlusOutlined />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  return (
    <Row
      style={{ minWidth: '100%', minHeight: '100vh' }}
      className='project-form center'
      justify='center'
      align='middle'
    >
      <Form
        form={form}
        name='basic'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        style={{ width: 500 }}
      >
        <Form.Item
          className='form-input-field'
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Please input your Name!' }]}
        >
          <Input className='single-input-field' />
        </Form.Item>

        <Form.Item
          className='form-input-field'
          label='Background Color'
          name='backfroundColor'
          rules={[
            { required: true, message: 'Please input  Background Color!' },
          ]}
        >
          <Input className='single-input-field' />
        </Form.Item>

        <Form.Item
          className='form-input-field'
          label='Project Link'
          name='link'
          rules={[{ required: true, message: 'Please input  Project Link!' }]}
        >
          <Input className='single-input-field' />
        </Form.Item>

        <Form.Item
          className='form-input-field'
          label='Title Text Color'
          name='TitleColor'
          rules={[{ required: true, message: 'Please input  Title Color!' }]}
        >
          <Input value={'#263238'} className='single-input-field' />
        </Form.Item>

        <Form.Item
          className='form-input-field'
          label='Description'
          name='description'
          rules={[{ required: true, message: 'Please input  Description!' }]}
        >
          <TextArea rows={4} className='single-input-text-area' />
        </Form.Item>
        <Form.Item
          className='form-input-field'
          label='Description Color'
          name='descriptionColor'
          rules={[
            { required: true, message: 'Please input  description color!' },
          ]}
        >
          <Input className='single-input-field' />
        </Form.Item>
        <Form.Item
          className='form-input-field'
          name='isMobile'
          valuePropName='checked'
        >
          <Checkbox>Mobile App</Checkbox>
        </Form.Item>
        <Upload
          disabled={loading}
          name='image'
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          beforeUpload={(e) => beforeUpload(e)}
        >
          {uploadButton}
        </Upload>

        <Form.Item className='form-input-field' label='Tags' name='tag'>
          <Input
            placeholder='Tag'
            className='single-input-field'
            onChange={(e) => handleSingleTagChange()}
          />
        </Form.Item>
        <div className='tags'>
          {tags.map((tag: any) => {
            return (
              <Tag
                color={'green'}
                onClick={() => deleteTag(tag)}
                className='tag'
                key={tag}
              >
                {tag}
              </Tag>
            )
          })}
        </div>
        <Form.Item
          className='form-input-field'
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Button
            type='primary'
            htmlType='submit'
            className='button'
            loading={uploading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  )
}

export default ProjectForm
