import ScrollCarousel from '../../Helper/ScrollCarousel'
import SingleProject from '../SingleProject'
import './style.scss'
import { collection, getDocs } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import firebase from '../../../firebase'

const Project = () => {
  const [projects, setProjects] = useState<any>([])

  useEffect(() => {
    getData()
  }, [])
  const getData = useCallback(async () => {
    setProjects([])
    const projectCol = collection(firebase, 'projects')
    const projectSnapshot = await getDocs(projectCol)
    const projectList = projectSnapshot.docs.map((doc) => doc.data())
    projectList?.map((project) => {
      setProjects([...projects, ...projectList])
    })
  }, [])

  return (
    <ScrollCarousel>
      <div className='center'>
        <div className='title'>
          <h1 style={{ color: '#FD3221' }}>Project</h1>
        </div>
      </div>
      {projects.map((project: any) => {
        return <SingleProject key={project?.id} project={project} />
      })}
    </ScrollCarousel>
  )
}

export default Project
