import React from 'react'
import './style.scss'
import { FiMail, FiGithub, FiLinkedin, FiInstagram } from 'react-icons/fi'
import { Tooltip } from 'antd'

const Contact = () => {
  const contacts = [
    {
      icon: <FiMail />,
      link: 'mailto:ashim.bhatta000@gmail.com',
      name: 'ashim.bhatta000@gmail.com',
    },
    {
      icon: <FiGithub />,
      link: 'https://github.com/ashim-bhatta',
      name: 'ashim-bhatta',
    },
    {
      icon: <FiLinkedin />,
      link: 'https://www.linkedin.com/in/ashim-bhatta-76122b183/',
      name: 'ashim-bhatta-76122b183/',
    },
    {
      icon: <FiInstagram />,
      link: 'https://www.instagram.com/asheem_bhatta/',
      name: 'asheem_bhatta',
    },
  ]

  const handelClick = (link: string) => {
    window.open(link, '_blank')
  }

  return (
    <div className='contact center'>
      <div className='white-bg center'>
        <div className='title'>
          <h1>Social </h1>
        </div>
        <div className='contact-icons center'>
          {contacts.map((contact) => {
            return (
              <div
                className='contact-icon'
                key={contact?.link}
                onClick={() => handelClick(contact?.link)}
              >
                {contact?.icon}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Contact
