import React, { useCallback, useEffect, useState } from 'react'
import ScrollCarousel from '../../Helper/ScrollCarousel'
import './style.scss'
import { collection, getDocs } from 'firebase/firestore'
import firebase from '../../../firebase'
import SingleVideo from '../SingleVideo'

const Videos = () => {
  const [videos, setVideos] = useState<any>([])

  useEffect(() => {
    getData()
  }, [])
  const getData = useCallback(async () => {
    setVideos([])
    const videoCol = collection(firebase, 'videos')
    const videoSnapshot = await getDocs(videoCol)
    const videoList = videoSnapshot.docs.map((doc) => doc.data())
    videoList?.map((video) => {
      setVideos([...videos, ...videoList])
    })
  }, [])

  console.log({ videos })

  return (
    <ScrollCarousel>
      <div className='center'>
        <div className='title'>
          <h1>Videos</h1>
        </div>
      </div>
      {videos?.map((video: any) => {
        return <SingleVideo key={video?.id} video={video} />
      })}
    </ScrollCarousel>
  )
}

export default Videos
