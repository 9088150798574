import * as THREE from 'three'
import { useRef, useState, useMemo, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Text, TrackballControls } from '@react-three/drei'
import './style.scss'

const Word = ({ children, ...props }: any) => {
  const color = new THREE.Color()
  const fontProps = {
    font: '/Inter-Bold.woff',
    fontSize: 2,
    letterSpacing: -0.05,
    lineHeight: 1,
    'material-toneMapped': false,
  }
  const ref = useRef<any>()
  const [hovered, setHovered] = useState(false)
  const over = (e: any) => (e.stopPropagation(), setHovered(true))
  const out = () => setHovered(false)

  useEffect(() => {
    if (hovered) document.body.style.cursor = 'pointer'
    document.body.style.cursor = 'auto'
  }, [hovered])

  useFrame(({ camera }) => {
    ref.current.quaternion.copy(camera.quaternion)
    ref.current.material.color.lerp(
      color.set(hovered ? '#FF22ED' : '#263238'),
      0.1
    )
  })
  return (
    <Text
      ref={ref}
      onPointerOver={over}
      onPointerOut={out}
      {...props}
      {...fontProps}
      children={children}
    />
  )
}

const Cloud = ({ count = 4, radius = 20 }) => {
  var index = 0
  const indexRef = useRef()
  const skills = [
    'HTML',
    'CSS',
    'SCSS',
    'JAVA SCRIPT',
    'TYPE SCRIPT',
    'REACT JS',
    'REACT NATIVE',
    'ANTD',
    'NPM',
    'GIT',
    'PYTHON',
    'FAST API',
    'DART',
    'Flutter',
  ]

  const getSingleText = () => {
    const text = skills[index]
    index++
    if (index === skills.length) {
      index = 0
    }
    return text
  }

  const words = useMemo(() => {
    const temp = []
    const spherical = new THREE.Spherical()
    const phiSpan = Math.PI / (count + 1)
    const thetaSpan = (Math.PI * 2) / count
    for (let i = 1; i < count + 1; i++)
      for (let j = 0; j < count; j++)
        temp.push([
          new THREE.Vector3().setFromSpherical(
            spherical.set(radius, phiSpan * i, thetaSpan * j)
          ),
          getSingleText(),
        ])

    return temp
  }, [count, radius])

  return (
    <>
      {words.map(([pos, word], index) => (
        <Word key={index} position={pos} children={word} />
      ))}
    </>
  )
}

const Skills = () => {
  return (
    <div className='center skills'>
      <div className='title title-position'>
        <h1>Skills</h1>
      </div>
      <Canvas
        dpr={[1, 2]}
        camera={{ position: [0, 0, 35], fov: 90 }}
        className='skills__canvas'
      >
        <fog attach='fog' args={['#202025', 0, 80]} />
        <Cloud count={8} radius={20} />
        <TrackballControls noZoom />
      </Canvas>
    </div>
  )
}

export default Skills
