import { Image } from 'antd'
import './style.scss'

const Header = () => {
  return (
    <div className='header center'>
      <div className='profile-image'>
        <Image
          src={require('../../../Assets/Images/ashim.jpeg')}
          preview={false}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className='title text-position'>
        <h1>ashim</h1>
        <h1>bhatta</h1>
      </div>
      <div className='my-bio'>
        <span>Ashim is a </span>
        <p>
          Knowledgeable Front End Developer specializing in collaborating with
          customers to gather requirements, produce plans, and improve designs
          for usability and functionality.
        </p>
      </div>
    </div>
  )
}

export default Header
