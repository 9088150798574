import { Col, Image, Row } from 'antd'
import React, { useState } from 'react'
import './style.scss'
import { BsArrowUpRight } from 'react-icons/bs'

const SingleVideo = ({ video }: any) => {
  const handelClick = () => {
    window.open(`https://www.youtube.com/watch?v=${video?.videoId}`, '_blank')
  }

  return (
    <div className='video center center'>
      <Row
        style={{ width: '100%', height: '100%' }}
        className='video__container center'
        gutter={30}
      >
        <Col style={{ position: 'relative' }}>
          <Image
            src={video?.thumnail}
            alt={video?.title}
            preview={false}
            className='video__thumnail'
          />
          <div className='playbtn center' onClick={() => handelClick()}>
            <div className='white' onClick={() => handelClick()}></div>
          </div>
        </Col>
        <Col>
          <h2>{video?.title}</h2>
          <div className='play-btn' onClick={handelClick}>
            Watch on youtube
            <div className='arrow-icon center'>
              <BsArrowUpRight />
            </div>
          </div>
        </Col>
      </Row>
      <div className='bg'></div>
    </div>
  )
}

export default SingleVideo
