import Contact from '../../Component/Contract'
import Header from '../../Component/Header'
import Project from '../../Component/Project'
import Skills from '../../Component/Skills'
import Videos from '../../Component/Videos'
import './style.scss'

const Home = () => {
  return (
    <div className='container'>
      <Header />
      <Project />
      <Skills />
      <Videos />
      <Contact />
    </div>
  )
}

export default Home
