import React, { useCallback, useEffect, useState } from 'react'
import Home from './View/Page/Home'
import { Routes, Route, Link } from 'react-router-dom'
import ProjectForm from './View/Page/ProjectForm'
import axios from 'axios'
import { addDoc, collection } from 'firebase/firestore'
import firebase from './firebase'

const App = () => {
  const [userLocation, setUserLocation] = useState({})

  useEffect(() => {
    getUserLocation()
  }, [])

  useEffect(() => {
    if (Object.keys(userLocation).length > 0) {
      setTimeout(() => addDataToFirebase(userLocation), 2000)
    }
  }, [userLocation])

  const getUserLocation = async () => {
    try {
      const res = await axios.get('https://ipapi.co/json/')
      setUserLocation(res?.data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const addDataToFirebase = useCallback(async (userLocation: any) => {
    const item = await localStorage.getItem('visited')
    if (item !== 'true') {
      const collectionRef = collection(firebase, 'usersLocations')
      const payload = { ...userLocation }
      addDoc(collectionRef, payload)
      localStorage.setItem('visited', 'true')
    }
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/add-project' element={<ProjectForm />} />
    </Routes>
  )
}

export default App
