import React, { useRef, useState } from 'react'
import './style.scss'
import useMouse from '@react-hook/mouse-position'
import { Image, Tag } from 'antd'

const SingleProject = ({ project }: any) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [imagePosition, setImagePosition] = useState({ left: 0, top: 0 })
  const ref = useRef(null)
  const mouse: any = useMouse(ref, {})
  const [showImage, setShowImage] = useState(false)

  const detectCursor = (event: any) => {
    setPosition({ x: mouse?.x, y: mouse?.y })
    setImagePosition({ left: mouse?.x, top: mouse?.y })
  }

  console.log({ project })

  return (
    <div
      className='box center'
      ref={ref}
      onMouseMove={(e) => {
        detectCursor(e)
      }}
      onMouseOver={(e) => {
        detectCursor(e)
      }}
    >
      <div
        className='bg center'
        style={{ background: project?.backfroundColor }}
      >
        <div className='project center'>
          <h1
            onMouseOver={() => setShowImage(true)}
            onMouseOut={() => setShowImage(false)}
          >
            {project?.name}
          </h1>
          <p
            style={{
              color: project?.TitleColor,
            }}
          >
            {project?.description}
          </p>
          <div className='tags'>
            {project?.tags.map((tag: any) => {
              return (
                <Tag color={'green'} className='tag' key={tag}>
                  {tag}
                </Tag>
              )
            })}
          </div>

          <div className='link'>
            {'Demo URL =>'}
            <a href={project?.link} target='_blank'>
              {project?.link}
            </a>
          </div>
        </div>
      </div>

      <div
        className='outer-cursor'
        style={{ left: position?.x - 20, top: position?.y - 20 }}
      >
        <div className='inner-cursor'></div>
      </div>
      {showImage && (
        <Image
          style={{ ...imagePosition }}
          className={
            project?.isMobile ? 'product-image mobile' : 'product-image'
          }
          src={project?.image}
          preview={false}
        />
      )}

      <div className='image-container'>
        <Image
          className={
            project?.isMobile
              ? 'product-image-mobile mobile'
              : 'product-image-mobile'
          }
          src={project?.image}
          preview={false}
        />
        <div className='bg-image'></div>
      </div>
    </div>
  )
}

export default SingleProject
