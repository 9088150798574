// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'


const firebaseConfig = {
  apiKey: 'AIzaSyC90wktYS_VMBi8LhyLLUabMAwrteWwUq4',
  authDomain: 'portfolio-ef9e7.firebaseapp.com',
  projectId: 'portfolio-ef9e7',
  storageBucket: 'portfolio-ef9e7.appspot.com',
  messagingSenderId: '1027445502310',
  appId: '1:1027445502310:web:13973d55c112761d2c18a7',
  measurementId: 'G-Y36EM4E2L8',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const storage = getStorage(app, "gs://portfolio-ef9e7.appspot.com/");
export default getFirestore()